<template>
	<button class='btn mb-0' :type="type" :disabled="disabled || loading" :class="getClasses(variant, color, size, fullWidth, active, circle)">
		<template v-if="loading">
			<div class="loader"></div>
		</template>

		<slot v-else />
	</button>
</template>

<script>
export default {
	name: 'SoftButton',

	props: {
		color: {
			type: String,
			default: 'success',
		},
		size: {
			type: String,
			default: 'md',
		},
		variant: {
			type: String,
			default: 'fill',
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		active: {
			type: Boolean,
			default: false,
		},
		circle: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'button'
		}
	},

	methods: {
		getClasses: (variant, color, size, fullWidth, active, circle) => {
			let colorValue, sizeValue, fullWidthValue, activeValue, circleValue;

			// Setting the button variant and color
			if (variant === 'gradient') {
				colorValue = `bg-gradient-${color}`;
			} else if (variant === 'outline') {
				colorValue = `btn-outline-${color}`;
			} else {
				colorValue = `btn-${color}`;
			}

			sizeValue = size ? `btn-${size}` : null;

			fullWidthValue = fullWidth ? `w-100` : null;

			activeValue = active ? `active` : null;

			circleValue = circle ? `btn-circle` : null;

			return `${colorValue ?? ''} ${sizeValue ?? ''} ${fullWidthValue ?? ''} ${activeValue ?? ''} ${circleValue ?? ''}`;
		},
	},

}
</script>

<style lang="scss">
.loader {
	width: 15px;
	margin: 0 auto;
	aspect-ratio: 1;
	border: 2px solid;
	border-radius: 50%;
	animation: l1 1s infinite;
	border-color: transparent #fff;
}

@keyframes l1 {
	to {
		transform: rotate(.5turn)
	}
}
</style>
