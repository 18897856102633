<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination pagination-md justify-content-center">

            <li class="page-item mx-1" ref="navPrev" :class="{ disabled: isValueFirst }" v-on="isValueFirst ? {} : { click: goPrevious }">
                <a class="page-link" href="javascript:;" tabindex="-1">
                    <i class="fa fa-angle-left"></i>
                    <span class="sr-only">Previous</span>
                </a>
            </li>

            <li class="page-item mx-1" v-for="(item, index) in items" :class="{ active: item === currentPage }" :key="'paging_' + index">
                <a v-if="isNaN(Number(item))" class="page-link" href="javascript:;">{{ item }}</a>
                <a v-else class="page-link" :class="{ active: item === value }" @click="goPage(item)" href="javascript:;">{{ item }}</a>
            </li>

            <li class="page-item mx-1" ref="navNext" :class="{ disabled: isValueLast }" v-on="isValueLast ? {} : { click: goNext }">
                <a class="page-link" href="javascript:;">
                    <i class="fa fa-angle-right"></i>
                    <span class="sr-only">Next</span>
                </a>
            </li>

        </ul>
    </nav>
</template>

<script>
export default {

    name: 'Pagination',

    props: {
        currentPage: {
            type: [String, Number],
            default: 1
        },
        lastPage: { // 200
            type: [String, Number],
            default: 1
        }
    },

    data: () => ({
        value: 1,
        length: 0,
        perPage: 20,
        maxButtons: 0,
        totalVisible: 10,
    }),

    computed: {

        isValueLast() {
            return this.value >= this.length;
        },

        isValueFirst() {
            return this.value <= 1;
        },

        items() {
            const maxLength = this.totalVisible > this.maxButtons ? this.maxButtons : this.totalVisible || this.maxButtons;

            if (this.length <= maxLength || maxLength < 1) {
                return this.getRange(1, this.length);
            }

            const even = maxLength % 2 === 0 ? 1 : 0;
            const left = Math.floor(maxLength / 2);
            const right = this.length - left + 1 + even;

            if (this.value > left && this.value < right) {
                const start = this.value - left + 2;
                const end = this.value + left - 2 - even;

                return [1, '...', ...this.getRange(start, end), '...', this.length];
            } else if (this.value === left) {
                const end = this.value + left - 1 - even;

                return [...this.getRange(1, end), '...', this.length];
            } else if (this.value === right) {
                const start = this.value - left + 1;

                return [1, '...', ...this.getRange(start, this.length)];
            } else {
                return [...this.getRange(1, left), '...', ...this.getRange(right, this.length)];
            }
        },
    },

    mounted() {
        this.value = this.currentPage
        this.length = Number(this.lastPage)

        this.setMaxButtons();
    },

    methods: {

        goNext(e) {
            e.preventDefault();
            this.$emit('paginate', this.value = this.value + 1);
            this.$emit('next');
        },

        goPrevious(e) {
            e.preventDefault();
            this.$emit('paginate', this.value = this.value - 1);
            this.$emit('previous');
        },

        goPage(e) {
            this.value = e
            this.$emit('paginate', e)
        },

        getRange(from, to) {
            const range = [];

            from = from > 0 ? from : 1;

            for (let i = from; i <= to; i++) {
                range.push(i);
            }

            return range;
        },

        setMaxButtons() {
            const containerWidth = this.$el && this.$el.parentElement ? this.$el.parentElement.clientWidth : window.innerWidth;

            const navButton = this.$refs.navNext.getBoundingClientRect();

            // width of the items considering navItem.height = item.width
            const itemWidth = navButton.height;
            const navItemsWidth = navButton.width * 2;

            this.maxButtons = Math.floor((containerWidth - navItemsWidth) / itemWidth);
        },

    }

}
</script>
